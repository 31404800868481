import { useEffect } from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { useInView } from 'react-intersection-observer';

import ScrollReveal from '@/components/ScrollReveal';
import { IThreeColumnCTABlock } from '@/interfaces/pages';
import { AspectRatio, CircleArrow, Container, Section } from '@/styles/global';

import { Card, Copy, Intro, Row } from './styles';

interface IThreeColumnCTA {
    content: IThreeColumnCTABlock;
}

const ThreeColumnCTA = ({ content }: IThreeColumnCTA) => {
    const { background, cards, title, introduction } = content;

    const { ref, inView } = useInView({
        threshold: 0.5,
    });

    useEffect(() => {
        if (inView) {
            document.body.className = background;
        }
    }, [inView, background]);

    return (
        <Section ref={ref}>
            <Container size="medium">
                {title && (
                    <ScrollReveal>
                        <Intro>
                            <h2>{title}</h2>
                            {introduction && <p>{introduction}</p>}
                        </Intro>
                    </ScrollReveal>
                )}
                <ScrollReveal>
                    <Row>
                        {cards.map((card) => (
                            <Card key={card.id}>
                                <Link href={card.cta.url}>
                                    <AspectRatio ratio="4:3">
                                        <Image
                                            src={card.image.path}
                                            alt={card.image.alt}
                                            width={282}
                                            height={282}
                                            unoptimized
                                        />
                                    </AspectRatio>
                                    <Copy>
                                        <h3>{card.title}</h3>
                                        <CircleArrow variant="white" />
                                    </Copy>
                                </Link>
                            </Card>
                        ))}
                    </Row>
                </ScrollReveal>
            </Container>
        </Section>
    );
};

export default ThreeColumnCTA;
