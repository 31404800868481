import styled from 'styled-components';

export const Intro = styled.div`
    text-align: center;
    margin-bottom: 4.5rem;

    p {
        font-size: 1rem;
        line-height: 1.5rem;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        p {
            font-size: 1.5rem;
            line-height: 1.8rem;
        }
    }
`;

export const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    align-items: flex-start;
    grid-template-rows: auto;
    gap: 3rem;
    overflow: hidden;

    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1.5rem;
    }
`;

export const Card = styled.div`
    position: relative;
    border-radius: 0.75rem;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colours.surface.premium};
    a {
        color: white;
    }

    &:nth-of-type(2) {
        background-color: ${({ theme }) => theme.colours.secondary.alertRed};
    }

    &:nth-of-type(3) {
        background-color: ${({ theme }) => theme.colours.secondary.chartreuse};
        a {
            color: ${({ theme }) => theme.colours.surface.deepBlue};
        }
    }

    a {
        text-decoration: none;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        margin-top: 3rem;

        &:nth-child(2n) {
            margin-top: 0;
        }
    }
`;

export const Copy = styled.div`
    height: 11.56rem;
    padding: 1.5rem;
    //color: ${({ theme }) => theme.colours.surface.deepBlue};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    h3 {
        margin-bottom: 1.06rem;
    }
`;
